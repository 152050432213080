@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Barriecito&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Mogra&family=Montserrat:ital,wght@0,100..900;1,100..900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Protest+Revolution&display=swap');

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";



.slick-dots {
  position: absolute;
  bottom: 20px; /* Space for the dots */
  left: 50%;
  transform: translateX(-50%);
  display: flex; /* Ensure dots align properly */
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 10; /* Ensure visibility over the carousel */
}

/* Add padding to the slider container */
.image-gallery .slick-slider {
  padding-bottom: 40px; /* Adjust as needed to make space for dots */
}

/* Responsive adjustments for larger screens */
@media (min-width: 1024px) {
  .slick-dots {
    bottom: 20px; /* Adjust for larger screens */
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.privacy-policy{
  padding-left: 10px;
  padding-right: 10px;
}


*{
  overflow-x: hidden;
}

h1{
  overflow: hidden;
}
h2{
  overflow: hidden;
}


.mogra-regular {
  font-family: "Mogra", system-ui;
  font-weight: 400;
  font-style: normal;
}


html,
body {
  margin: 0;
  padding: 0;
}



.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Space between images */
  justify-content: center;
}

.image-item {
  border: 2px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 300px; /* Fixed width for images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-item img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  display: block;
}

.image-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
